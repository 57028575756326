import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout"


const KirpichDom = ({ data }) => {
  return (
    <Layout>
      <div className="mt-8">
        <div className="bg-transparent">
          <h2
            className="text-2xl md:text-3xl lg:text-4xl xl:text-4xl"
            style={{
              fontFamily: `Montserrat`,
              fontWeight: `bold`,
              color: `white`,
              padding: `1rem`,
            }}
          >
            КИРПИЧНЫЕ ДОМА
          </h2>
        </div>
      </div>
      <section>
        <div className="grid grid-cols-1 mt-20 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
          {data.allContentfulBrickhouse.nodes.map(kirpich => (
            <div key={kirpich.id}>
              <div className="w-full flex flex-col mb-6 md:flex-col xl:flex-col">
                <div className="flex p-2 justify-center items-center">
                  <Img
                    className="w-full"
                    fluid={kirpich.image.fluid}
                    alt={kirpich.title}
                    imgStyle={{ objectFit: "contain" }}
                  />
                </div>
                <div className="p-2">
                  <h3
                    className="text-xl md:text-2xl lg:text-2xl xl:text-2xl"
                    style={{
                      fontFamily: `Montserrat`,
                      color: `#F1F1F2`,
                    }}
                  >
                    {kirpich.title}
                  </h3>
                  <p
                    className="mt-6 text-sm md:text-sm lg:text-sm xl:text-sm"
                    style={{
                      fontFamily: `Montserrat`,

                      color: `#F1F1F2`,
                    }}
                  >
                    {documentToReactComponents(kirpich.description.json)}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default KirpichDom

export const PageQuery = graphql`
  query Kirpich {
    allContentfulBrickhouse {
      nodes {
        description {
          json
        }
        image {
          fluid(maxWidth: 600, quality: 35) {
            ...GatsbyContentfulFluid
          }
        }
        title
      }
    }
  }
`
